<button
  style="margin: 0 3px"
  mat-mini-fab
  color="inherit"
  [matMenuTriggerFor]="profilemenu"
  class="d-flex justify-content-center"
>
  <i
    class="iconify icon-20 d-flex"
    data-icon="solar:user-line-duotone"
  ></i>
</button>

<mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
  <button mat-menu-item rou [routerLink]="['/pages/user-management/user-profile']">
    <mat-icon class="d-flex align-items-center"
    ><i
      data-icon="solar:shield-user-line-duotone"
      class="iconify icon-22"
    ></i></mat-icon
    >{{'TOOLBAR.MY_PROFILE' | translate}}
  </button>
  <button mat-menu-item (click)="refresh()">
    <mat-icon class="d-flex align-items-center">
      <i
      data-icon="solar:refresh-circle-line-duotone"
      class="iconify icon-22"
    ></i></mat-icon
    >{{'TOOLBAR.REFRESH' | translate}}
  </button>

  <div class="p-x-12 m-t-12">
    <button
      (click)="logout()"
      mat-stroked-button
      color="primary"
      class="w-100"
    >{{'TOOLBAR.LOGOUT' | translate}}</button
    >
  </div>
</mat-menu>
