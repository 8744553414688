<div [style.width.px]="width" style="display: contents" class="toolBar" #fixedHeader>
  <!--  [ngStyle]="production ? prodHeaderStyle : headerStyle"-->
  <mat-toolbar class="topbar">


    <button
      mat-mini-fab
      color="inherit"
      (click)="toggleSideNav()"
      class="d-flex justify-content-center"
    >
      <i
        class="iconify icon-20 d-flex"
        data-icon="solar:hamburger-menu-line-duotone"
      ></i>
    </button>


    <span class="toolBar-spacer"></span>
    <div style="display: flex ; align-items: center"
         [style.margin-right]="_translate.currentLang !== 'ar' ? '20px' : '0'"
         [style.margin-left]="_translate.currentLang === 'ar' ? '20px' : '0'">

      @if (currentUser.isFreeTrial) {
        <button
          mat-mini-fab
          color="red"
          aria-label="Notifications"
          class="d-flex justify-content-center"
        >
    <span [matBadge]="currentUser.remainingDay"  [matBadgeHidden]="!currentUser.isFreeTrial"
          matBadgeOverlap="false" matBadgeSize="small">
      <i class="iconify icon-20 d-flex" data-icon="ic:round-hourglass-empty"></i>
    </span>
        </button>
      }







      @if (userType.isCustomer()) {
        <div tourAnchor="header.first">
          <button
            style="margin: 0 3px"
            mat-mini-fab
            color="inherit"
            (click)="openSub()"
            class="d-flex justify-content-center"
          >
            <i
              class="iconify icon-20 d-flex"
              data-icon="mdi:card-membership"
            ></i>
          </button>
        </div>

      }

      @if (userType.isCustomer()) {
        <div tourAnchor="header.second">
          <button
            style="margin: 0 3px"
            mat-mini-fab
            color="inherit"
            (click)="openCustomerProgressDialog()"
            class="d-flex justify-content-center"
          >
            <i
              class="iconify icon-20 d-flex"
              data-icon="healthicons:market-stall-outline"
            ></i>
          </button>
        </div>
      }


      <notifications></notifications>
      <app-user-menu></app-user-menu>
      <app-languages></app-languages>
      <wifi-connection></wifi-connection>
    </div>
  </mat-toolbar>
</div>
