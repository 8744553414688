<mat-toolbar color="primary" style="position: relative;">
  @if (preventBack) {
    <button mat-icon-button>
      <mat-icon>
        ballot
      </mat-icon>
    </button>
  } @else {
    @if(routeLink) {
    <button mat-icon-button [routerLink]="routeLink">
      <mat-icon>
        {{ _translate.currentLang !== 'ar' ? 'arrow_back' : 'arrow_forward' }}
      </mat-icon>
    </button>
    } @else {
      <button mat-icon-button (click)="emitAction()">
      <mat-icon>
        {{ _translate.currentLang !== 'ar' ? 'arrow_back' : 'arrow_forward' }}
      </mat-icon>
    </button>
    }
  }
  <span>{{ title| translate }}</span>
  <div class="spacer"></div>
  <ng-content></ng-content>
</mat-toolbar>
