import { Component ,Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-guidance',
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    TranslateModule
  ],
  templateUrl: './guidance.component.html',
  styleUrl: './guidance.component.scss'
})
export class GuidanceComponent {
  @Input() label: string;
}
