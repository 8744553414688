<!--<div class="wifi-button">-->
<!--  &lt;!&ndash; <img [src]="statusWifi"> &ndash;&gt;-->
<!--  <div class="img">-->
<!--    <div style="height: 30px" *ngIf="!isConnected">-->
<!--      &lt;!&ndash;          <img style="width: 30px; height: 30px" src="assets/images/no-wifi.png" alt="not-connected">&ndash;&gt;-->
<!--      <span style="color: red" class="material-icons material-icons-round icon">wifi_off</span>-->
<!--    </div>-->
<!--    <div   *ngIf="isConnected">-->
<!--      &lt;!&ndash;          <img style="width: 30px; height: 30px" src="assets/images/wifi.png" alt="connected">&ndash;&gt;-->
<!--      <span style="color: #22B92D" class="material-icons material-icons-round icon">wifi</span>-->

<!--    </div>-->
<!--  </div>-->
<!--</div>-->



<button
  style="margin: 0 3px;cursor: default"
  mat-mini-fab
  color="red"
  aria-label="Notifications"
  class="d-flex justify-content-center"
  #notificationsOrigin
>

  @if (isConnected) {
    <i
      style="color: #22B92D"
      class="iconify icon-20 d-flex"
      data-icon="pepicons-pop:wifi"
    ></i>
  } @else {
    <i
      style="color: red"
      class="iconify icon-20 d-flex"
      data-icon="ph:wifi-x-bold"
    ></i>
  }
</button>
