import {delay, map} from 'rxjs/operators';
import {Item} from './../../../shared/modal/inventory/item/item';
import {Observable, of} from 'rxjs';
import {BaseService} from './../../../shared/service/base.service';
import {Injectable} from '@angular/core';
import {Category} from '../../../shared/modal/inventory/category/category';
import { OrderStatus } from 'src/app/shared/modal/setup/order-status';
import { HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrdersCreationService {

  // keep order data like desc , delivry method and other values saved
  private _orderData: any = {};
  // save session data
  private sessionData = null;
  private selectedPosItems = JSON.parse(localStorage.getItem('SelectedPosItems'));

  // save selected items
  private SelectedItems: Item[] = [];
  // backup items
  private _items: Item[] = [];
  private _categories: Category[] = [];
  private _deliveryMethods: any[] = [];
  private _invoiceTypes: any[] = [];
  private _offlineCustomers: any[] = [];
  private _orderStatusByType: any[] = [];
  constructor(private _base: BaseService) {
  }

  get orderData() {
    return this._orderData;
  }

  set orderData(value) {
    this._orderData = value;
  }

  get items() {
    return this._items;
  }

  set items(items) {
    this._items = items;
  }

  get categories() {
    return this._categories;
  }

  set categories(categories) {
    this._categories = categories;
  }

  get deliveryMethods() {
    return this._deliveryMethods;
  }

  set deliveryMethods(deliveryMethods) {
    this._deliveryMethods = deliveryMethods;
  }
  get invoiceTypes() {
    return this._invoiceTypes;
  }

  set invoiceTypes(invoiceTypes) {
    this._invoiceTypes = invoiceTypes;
  }

  //_offlineCustomers

  get offlineCustomers() {
    return this._offlineCustomers;
  }

  set offlineCustomers(offlineCustomers) {
    this._offlineCustomers = offlineCustomers;
  }

  // _orderStatusByType

  get orderStatusByType() {
    return this._orderStatusByType;
  }

  set orderStatusByType(orderStatusByType) {
    this._orderStatusByType = orderStatusByType;
  }

  getSelectedItem() {
    return this.SelectedItems;
  }

  setSelectedItem(SelectedItem: Item[]) {
    this.SelectedItems = SelectedItem;
  }

  getSessionData() {
    return this.sessionData;
  }

  setSessionData(sessionData: any) {
    this.sessionData = sessionData;
  }





  /**
   * get last session by user
   */
  getLastSessionByUser(): Observable<any> {
    return this._base.get(`api/v1/pos-session/last-session-user`);
  }



  /**
   * get items by branchid or category id or subcategory id
   * @param branchId
   * @param catId
   * @param subcatId
   */
  getItems(
    branchId: number,
    catId?: number,
    subcatId?: number
  ): Observable<any> {
    const posItems = localStorage.getItem('PosItems');
    if (posItems) {
      this.items = JSON.parse(posItems);
      return of(this.items);
    }
    return this._base.getOffline(
      `api/v1/branch-items/pos/${branchId}?${catId ? '&catId=' + catId : ''}${subcatId ? '&subCatId=' + subcatId : ''}`,true,true)
      .pipe(map(event => {
        console.log(event);
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          const progress = Math.round((100 * event.loaded) / event.total);
          // You can pass this progress value to the component to update the UI
          return { progress };
        case HttpEventType.Response:
          this.items = event.body?.data ? event.body.data : [];
          localStorage.setItem('PosItems', JSON.stringify(event.body.data));
          return this.items;
        default:
          return { progress: 0 };
      }
    }));
  }


  // getCategoriesByBranch(branchId): Observable<any> {
  //   const posCategories = localStorage.getItem('PosCategories');
  //   if (posCategories) {
  //     this.categories = JSON.parse(posCategories);
  //     return of(this.categories);
  //   }
  //   return this._base.get(`api/v1/category/findByBranch?branchId=${branchId}`).pipe(delay(500),map(res => {
  //     this.categories = res?.data ? res.data : [];
  //     localStorage.setItem('PosCategories', JSON.stringify(res.data));
  //     return res.data;
  //   }));
  // }

  getCategoriesByBranch(branchId): Observable<any> {
    const posCategories = localStorage.getItem('PosCategories');
    if (posCategories) {
      this.categories = JSON.parse(posCategories);
      return of(this.categories);
    }
    return this._base.getOffline(`api/v1/category/findByBranch?branchId=${branchId}`,true,true).pipe(
      delay(800),
      map(event => {
        switch (event.type) {
          case HttpEventType.DownloadProgress:
            const progress = Math.round((100 * event.loaded) / event.total);
            // You can pass this progress value to the component to update the UI
            return { progress };
          case HttpEventType.Response:
            this.categories = event.body?.data ? event.body.data : [];
            localStorage.setItem('PosCategories', JSON.stringify(event.body.data));
            return this.categories;
          default:
            return { progress: 0 };
        }
      })
    );
  }


  getDeliveryMethodsByBranch(branchId): Observable<any> {
    const PosDeliveryMethods = localStorage.getItem('PosDeliveryMethods');
    if (PosDeliveryMethods) {
      this.deliveryMethods = JSON.parse(PosDeliveryMethods);
      return of(this.deliveryMethods);
    }
    return this._base.getOffline(`api/v1/customer-branch/delivery-methods/${branchId}`,true,true)
      .pipe(delay(1000),map(event => {
        switch (event.type) {
          case HttpEventType.DownloadProgress:
            const progress = Math.round((100 * event.loaded) / event.total);
            // You can pass this progress value to the component to update the UI
            return { progress };
          case HttpEventType.Response:
            this.deliveryMethods = event.body?.data ? event.body.data : [];
            localStorage.setItem('PosDeliveryMethods', JSON.stringify(event.body.data));
            return this.deliveryMethods;
          default:
            return { progress: 0 };
        }
    }));
  }


  getInvoiceTypes(): Observable<any> {
    const PosInvoiceTypes = localStorage.getItem('PosInvoiceTypes');
    if (PosInvoiceTypes) {
      this.invoiceTypes = JSON.parse(PosInvoiceTypes);
      return of(this.invoiceTypes);
    }
    return this._base.getOffline(`api/v1/issuing-type/page`,true,true).pipe(delay(1400),
      map(event => {
        switch (event.type) {
          case HttpEventType.DownloadProgress:
            const progress = Math.round((100 * event.loaded) / event.total);
            // You can pass this progress value to the component to update the UI
            return { progress };
          case HttpEventType.Response:
            this.invoiceTypes = event.body?.data?.content ? event.body.data.content : [];
            localStorage.setItem('PosInvoiceTypes', JSON.stringify(event.body.data.content));
            return this.invoiceTypes;
          default:
            return { progress: 0 };
        }
    }));
  }

  /**
   * get offline customers
   */

  getOfflineCustomers(phoneNumber:any): Observable<any> {
    const PosOfflineCustomers = localStorage.getItem('PosOfflineCustomers');
    if (PosOfflineCustomers) {
      this.offlineCustomers = JSON.parse(PosOfflineCustomers);
      return of(this.offlineCustomers);
    }
    return this._base.getOffline(`api/v1/walking-consumer/nameAndPhone?name=${phoneNumber}`,true,true)
      .pipe(delay(1800),
        map(event => {
          switch (event.type) {
            case HttpEventType.DownloadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              // You can pass this progress value to the component to update the UI
              return { progress };
            case HttpEventType.Response:
              this.offlineCustomers = event.body?.data ? event.body.data : [];
              localStorage.setItem('PosOfflineCustomers', JSON.stringify(event.body.data));
              return this.offlineCustomers;
            default:
              return { progress: 0 };
          }
    }));
  }




  getOrderStatusByType(statusType:any): Observable<OrderStatus[]> {
    const PosOrderStatusType = localStorage.getItem('PosOrderStatusType');
    if (PosOrderStatusType) {
      this.orderStatusByType = JSON.parse(PosOrderStatusType);
      return of(this.orderStatusByType);
    }
    return this._base.get(`api/v1/order-status/findByStatusType?statusType=${statusType}`).pipe(delay(100),map(res => {
      this.orderStatusByType = res?.data?.content ? res?.data?.content : [];
      localStorage.setItem('PosOrderStatusType', JSON.stringify(res.data.content));
      return res.data.content;
    }));
  }



  /**
   * get units of measure
   */
  getUnits(): Observable<any> {
    return this._base.get('api/v1/unit');
  }

  /**
   * @param itemId
   */
  getItemAttachmentsById(itemId: number): Observable<any> {
    return this._base.get(`api/v1/item-attach/findByItemId?itemId=${itemId}`);
  }

  /**
   * end session
   */
  endSession(session: any): Observable<any> {
    return this._base.put(session, 'api/v1/pos-session');
  }

  public updateFromAndroid(message) {
    console.log('service message is >> ', message);
    //  this.searchValue  = message;
    alert('service message is >> ' + message);
  }

  public findItemColors(mainBranchItemId): Observable<any> {
    return this._base.get(`api/v1/item-rev-values/item/color?mainBranchItemId=${mainBranchItemId}`);
  }
  public findItemSizes(mainBranchItemId,colorId): Observable<any> {
    return this._base.get(`api/v1/branch-items/size?mainBranchItemId=${mainBranchItemId}&colorId=${colorId}`);
  }
  public approvalRequest(obj:any):Observable<any>{
    return  this._base.post(obj,'api/v1/approval-request')
  }

   openedOrdersCount(posSessionId): Observable<any> {
    return this._base.get(`api/v1/issuing-order/openedOrders/${posSessionId}`);
  }

   //TODO:: SET SERVICES IN LOCAL STORAGE TO ENABLE OFFLINE MODE

  //

}
