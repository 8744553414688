<div class="row screen-cards-container">
  @for (card of cards; track card.title) {
    <div class="col-sm-6 col-md-3 m-b-12">
    <mat-card class="screen-card"  [routerLink]='card.urlNavigate'>
        <mat-card-content class="p-16">
          <span class="p-10 d-inline-block shadow-3 rounded-8">
            <mat-icon color="primary">
              {{card.icon}}
            </mat-icon>
          </span>
          <h4 >
            {{card.title |translate}}

          </h4>
          <p  >
            {{card.details |translate}}
          </p>
        </mat-card-content>
    </mat-card>
    </div>
  }
</div>
