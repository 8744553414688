<mat-card class="p-x-12 p-y-16 d-flex flex-column align-items-center">
  @if (showBadge) {
    <div  class="accent-badge top-badge-left">
      <span>{{ badgeLabel  | translate }}</span>
    </div>
  }

    <div class="m-b-4">
      <img [ngSrc]="imagePath ? baseImageUrl +  imagePath: './assets/images/item.png'"
           onerror="this.src='./assets/images/item.png'"
           alt="" height="150" width="150">
    </div>
  <p class="f-w-600 text-center">{{ itemName }}</p>
  <div class="d-flex justify-content-center col-sm-12 col-md-12">
    @if (showCountAction) {
      <div class="col-sm-4 col-md-4 d-flex align-items-center justify-content-center">
        <button mat-icon-button  color="accent" class="mat-large-icon-button" (click)="emitIncrement()">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    }
    <div class="col-sm-4 col-md-4 d-flex align-items-center justify-content-center">
      <p class="f-w-600">{{ itemCount }}</p>
    </div>
    @if (showCountAction) {
      <div class="col-sm-4 col-md-4 d-flex align-items-center justify-content-center">
        <button mat-icon-button color="accent" class="mat-large-icon-button" (click)="emitDecrement()">
          <mat-icon>remove_circle</mat-icon>
        </button>
      </div>
    }
  </div>
</mat-card>


