import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {BaseService} from '../../service';
import {OrdersCreationService} from '../../../pages/point-of-sale/orders-creation/orders-creation.service';
import {UntypedFormControl} from '@angular/forms';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {environment} from '../../../../environments/environment';
import {DatePipe} from '@angular/common';
import {Invoice} from 'src/app/zatca-qr';

@Component({
  selector: 'pos-invoice',
  templateUrl: './pos-invoice.component.html',
  styleUrls: ['./pos-invoice.component.scss']
})
export class PosInvoiceComponent implements OnInit {
  remainBalanceControl = new UntypedFormControl(0);
  direction: string;
  header = null;
  body = null;
  print = null;
  hasInvoice = null;
  ordersCount = null;
  countOfOrders: number;
  yes = null;
  no = null;
  order: any;
  branchImageUrl;
  sessionData = JSON.parse(localStorage.getItem('SessionData'));
  currentUser = JSON.parse(window.localStorage.getItem('CURRENT_USER'));
  totalPrice = 0;
  totalDiscount = 0.0;
  totalTax = 0.0;
  date;
  qr = '';

  constructor(public _translate: TranslateService,
              private _base: BaseService,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<PosInvoiceComponent>,
              private _service: OrdersCreationService, public datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('LANG');
    this.direction = lang === 'ar' ? 'rtl' : 'ltr';
    this.order = this.data;
    this.branchImageUrl = environment.baseImageUrl + this.order.print.branch.imagePath;
    console.log(this.order);
    console.log(this.sessionData);
    if (this.order) {
      this.init();
      this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US');
       this.qrInit(this.order)
    }
  }

  init() {
    this.totalPrice = 0, this.totalDiscount = 0, this.totalTax = 0;
    this.order.issuingOrderDetails.forEach((orderItem: any) => {
      this.totalPrice += (orderItem.price * orderItem.qty);
      this.totalDiscount += (orderItem.discount * orderItem.qty);
      this.totalTax += orderItem.salesTaxValue * orderItem.qty;
    });


  }

  printPdf(reportObj, filename) {
    // printJS({
    //   printable: url,
    //   type: 'pdf',
    //   showModal: true,
    //   onIncompatibleBrowser: () => {
    //     console.log('Browser Not Compitable');
    //     window.alert('Browser Not Compitable');
    //   }
    // });
  }

  downloadPDF() {
    const data = document.getElementById('pdf-content');
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 80;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', [170, 80]);
        const position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, 80, imgHeight);

        this.dialogRef.close({
          data: {
            action: 'save',
            print: true,
            pdf:pdf,
            date:this.date
          },
        });

      });
    }
  }

  async qrInit(order) {
    let qr = null;
    let branchName = order.print.branch.branchName;
    let taxNumber = this.currentUser?.branchConfig?.taxNumber;
    branchName = new TextDecoder('utf-8').decode(new TextEncoder().encode(branchName));
    taxNumber = new TextDecoder('utf-8').decode(new TextEncoder().encode(taxNumber));
    const invoice = new Invoice({
      sellerName: branchName,
      vatRegistrationNumber: taxNumber,
      invoiceTimestamp: this.date,
      invoiceTotal: `${this.totalPrice.toFixed(2)}`,
      invoiceVatTotal: `${this.totalTax.toFixed(2)}`,
    });
     this.qr  = await invoice.render();
  }

  onCancel() {
    this.dialogRef.close({
      data: {
        action: 'cancel',
        print: false
      },
    });
  }

}

