import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatToolbar} from '@angular/material/toolbar';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'screen-toolbar',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    MatToolbar,
    TranslatePipe,
    RouterLink
  ],
  templateUrl: './screen-toolbar.component.html'
})
export class ScreenToolbarComponent {
  @Input() preventBack:boolean = false;
  @Input() title:string;
  @Input() routeLink:string;
  @Output() action:EventEmitter<any> = new EventEmitter();
  constructor(
    private locations: Location,
    public _translate: TranslateService,
  ) { }
  back() {
    this.locations.back();
  }

  emitAction() {
    this.action.emit();
  }
}
